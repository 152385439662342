<template>
    <!-- Color Code
#19885E
#1D9C6C
#20AC77
#1DBD81
#16C784
#2ED696 -->
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <div class="innerPage-banner heightCalc py-4 whiteBG">
            <div class="container-fluid h-100 calHeight">
                <div class="row h-100">
                    <div class="col-12 col-xl-3">
                        <!-- SearchBar -->
                        <economic-search></economic-search>
                        <div class="mainOverview p-3">
                            <h4 class="green mb-3 text-center">Money and Price</h4>
                            <!-- <div class="charIQ text-center my-3"><img src="assets/images/map12.png" alt="map" /></div> -->
                            <div id="indicator_moneyPriceChart" style="width: 100%; height: 220px"></div>
                            <perfect-scrollbar class="scroll-area scrollGDp">
                                <ul class="listCountry dot mb-0" v-if="percentData.length > 0">
                                    <li v-for="item, index in percentData.slice(0, 6)" :key="index">
                                        <a href="javascript:void(0)"
                                            class="d-flex justify-content-between align-items-center"> <span
                                                class="d-flex align-items-center">{{ item.Title }}</span>{{
                                                item.LatestValue }}{{ (item.Unit
                                            == 'percent') ? '%' : item.Unit
                                            }}</a>
                                    </li>
                                </ul>
                            </perfect-scrollbar>
                        </div>
                    </div>
                    <div class="col-12 col-xl-9">
                        <div class="mainRightMap">
                            <economic-mainnavbar></economic-mainnavbar>
                            <div class="flexAuto">
                                <economic-map v-show="Object.keys(store.economicGDPData).length > 0"></economic-map>
                                <carousel class="fullOverView" :settings="settings" :breakpoints="breakpoints"
                                    v-if="otherdata.length">
                                    <slide v-for="item, index in otherdata.slice(0, otherdata.length - 1)" :key="index">
                                        <div class="d-inline-flex align-items-center">
                                            <span class="flex-shrink-0 me-2 iconRate"><img
                                                    src="assets/images/exchange-rate.svg" alt="Generic image" /></span>
                                            <div class="flex-grow-1">
                                                <span class="iconcolor f-13">{{ item.Title }}</span>
                                                <h3 class="m-0">{{ item.LatestValue }}{{ (item.Unit == 'percent') ? '%'
                                                :
                                                item.Unit
                                                }}</h3>
                                            </div>
                                        </div>
                                    </slide>
                                    <template #addons>
                                        <Navigation />
                                    </template>
                                </carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import economicmainnavbar from "./files/economy_mainnavbar";
import economicmap from "./files/economy_map";
// import bottomnavbar from "./files/bottom_navbar";
import economicsearch from "./files/economy_search";
import bannerslider from "../../components/shared/banner_slider";
import { useMainStore } from "@/store";
import * as am5 from "@amcharts/amcharts5";
import * as index from "@amcharts/amcharts5/index";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Carousel, Slide, Navigation, } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            percentData: [],
            pointsData: [],
            otherdata: [],
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                // 700px and up
                767: {
                    itemsToShow: 2,
                    snapAlign: 'center',
                },
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
                1199: {
                    itemsToShow: 5,
                    snapAlign: 'start',
                },
            },
        }
    },
    watch: {
        'store.economicGDPData'() {
            this.drawMoneyPriceChart()
        }
    },
    components: {
        bannerSlider: bannerslider,
        economicMap: economicmap,
        // bottomNavbar:bottomnavbar,
        economicSearch: economicsearch,
        economicMainnavbar: economicmainnavbar,
        Carousel,
        Slide,
        Navigation,

    },
    methods: {
        drawMoneyPriceChart() {
            this.percentData = [];
            this.pointsData = [];
            this.otherdata = [];
            if (Object.keys(this.store.economicGDPData).length > 0) {
                for (let key in this.store.economicGDPData) {
                    for (let innerkey in this.store.economicGDPData[key]) {
                        this.percentData = [...this.percentData, ...this.store.economicGDPData[key][innerkey].filter(val => val.Unit.toLowerCase() == 'percent')]
                        this.pointsData = [...this.pointsData, ...this.store.economicGDPData[key][innerkey].filter(val => val.Unit.toLowerCase() == 'points')]
                        this.otherdata = [...this.otherdata, ...this.store.economicGDPData[key][innerkey].filter(val => (val.Unit.toLowerCase() != 'points') && (val.Unit.toLowerCase() != 'percent'))]
                    }
                }
                if (this.percentData.length > 5) {
                    this.otherdata = [...this.percentData.slice(6, this.percentData.length), ...this.otherdata]
                }

                this.percentData = [...new Set(this.percentData)]
                this.pointsData = [...new Set(this.pointsData)]
                this.otherdata = [...new Set(this.otherdata)]
            }
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'indicator_moneyPriceChart');
            d?.dispose();
            //console.log('money',am5.registry)
            var root = am5.Root.new("indicator_moneyPriceChart");

            root.interfaceColors.set("grid", am5.color(0xffffff));

            root.setThemes([am5themes_Animated.new(root)]);

            var chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    wheelX: "none",
                    wheelY: "none"
                })
            );
            var yRenderer = am5xy.AxisRendererY.new(root, { minGridDistance: 30 });

            var yAxis = chart.yAxes.push(
                am5xy.CategoryAxis.new(root, {
                    visible: false,
                    maxDeviation: 0.3,
                    categoryField: "Title",
                    renderer: yRenderer,
                    // tooltip: am5.Tooltip.new(root, {
                    //     labelText: "{Title} : {LatestValue}",
                    // }),
                })
            );
            chart.get("colors").set("colors", [
                am5.color(0x19885E),
                // am5.color(0x1D9C6C),
                // am5.color(0x20AC77),
                // am5.color(0x1DBD81),
                // am5.color(0x16C784),
                // am5.color(0x2ED696),
            ]);


            var xAxis = chart.xAxes.push(
                am5xy.ValueAxis.new(root, {
                    // visible: false,
                    maxDeviation: 0.3,
                    valueField: "LatestValue",
                    //min: 0,
                    renderer: am5xy.AxisRendererX.new(root, {})
                })
            );

            var series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: "Series 1",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueXField: "LatestValue",
                    sequencedInterpolation: true,
                    categoryYField: "Title",

                })
            );
            series.columns.template.setAll({
                tooltipText: "{Title}: {LatestValue}"
            });

            // var columnTemplate = series.columns.template;

            // columnTemplate.adapters.add("fill", (fill, target) => {
            //     return chart.get("colors").getIndex(series.columns.indexOf(target));
            // });
            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineX.set("visible", false);
            cursor.lineY.set("visible", false);
            yAxis.data.setAll(this.pointsData);
            series.data.setAll(this.pointsData);
            series.appear(1000);
            chart.appear(1000, 100);
        },
        callCountryEconomyData(country) {
            let formData = {
                "country": country,
                "group": ['Money', 'Prices']
            }
            this.store
                .getCountryEconomyData(formData, true)
                .then((response) => {
                })
                .catch((err) => console.log(err.message));
        },
    },
    mounted() {
        if (this.$route.query.country) {
            this.callCountryEconomyData(this.$route.query.country);
        }
        if (Object.keys(this.store.economicGDPData).length > 0) {
            am5.ready(() => {
                //this.drawMoneyPriceChart();
            });
        }
    }
}
</script>
<style>
#indicator_moneyPriceChart>div {
    display: none;
}

#indicator_moneyPriceChart>div:last-child {
    display: block !important;
}
</style>
