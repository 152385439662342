<template>
  <div class="overviewResponsiveMenu text-end">
    <a
      @click="overviewMenu = !overviewMenu"
      href="javascript:void(0)"
      class="resMenu d-none"
      ><vue-feather type="align-right"></vue-feather
    ></a>
    <ul
      class="d-flex headerTabMenu align-items-center flex-wrap justify-content-end"
      :class="overviewMenu ? 'active' : ''"
    >
      <li class="back d-none">
        <a
          href="javascript:void(0)"
          @click="overviewMenu = !overviewMenu"
          class="text-end"
          ><vue-feather type="arrow-right-circle"></vue-feather
        ></a>
      </li>
      <li :class="[{ active: tab == 'Overview' }]">
        <a
          href="javascript:void(0)"
          @click="
            tab = 'Overview';
            gotoPage('/economy-overview');
          "
          >Overview</a
        >
      </li>
      <li :class="[{ active: tab == 'GDP' }]">
        <a
          href="javascript:void(0)"
          @click="
            tab = 'GDP';
            gotoPage('gdp');
          "
          >GDP</a
        >
      </li>
      <li :class="[{ active: tab == 'Money&Prices' }]">
        <a
          href="javascript:void(0)"
          @click="
            tab = 'Money&Prices';
            gotoPage('money_and_price');
          "
          >Money</a
        >
      </li>
     <!--  <li :class="[{ active: tab == 'Markets' }]">
        <a
          href="javascript:void(0)"
          @click="
            tab = 'Markets';
            gotoPage('markets');
          "
          >Markets</a
        >
      </li> -->
      <li :class="[{ active: tab == 'Business&Consumer' }]">
        <a
          href="javascript:void(0)"
          @click="
            tab = 'Business&Consumer';
            gotoPage('business_consumer');
          "
          >Business & Consumer</a
        >
      </li>
      <li :class="[{ active: tab == 'Revevue&Expenditure' }]">
        <a
          href="javascript:void(0)"
          @click="
            tab = 'Revevue&Expenditure';
            gotoPage('revenue_and_expenditure');
          "
          >Revenue</a
        >
      </li>
      <li :class="[{ active: tab == 'Labour&Health' }]">
        <a
          href="javascript:void(0)"
          @click="
            tab = 'Labour&Health';
            gotoPage('labour_and_health');
          "
          >Labour & Health</a
        >
      </li>
      <li :class="[{ active: tab == 'Forex&Trade' }]">
        <a
          href="javascript:void(0)"
          @click="
            tab = 'Forex&Trade';
            gotoPage('forex_and_trade');
          "
          >Trade</a
        >
      </li>
      <!-- <li :class="[{ active: tab == 'Export&Import' }]">
        <a
          href="javascript:void(0)"
          @click="
            tab = 'Export&Import';
            gotoPage('imports_and_exports');
          "
          >Top Exports & Imports</a
        >
      </li> -->
      <li :class="[{ active: tab == 'News' }]">
        <a
          href="javascript:void(0)"
          @click="
            tab = 'News';
            gotoPage('latest_news');
          "
          >News</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
import { useMainStore } from "@/store";
export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {
      overviewMenu: false,
      tab: "",
    };
  },
  methods: {
    gotoPage(page) {
      if (this.tab != "Overview") {
        //this.$router.push({ name: "gdp", query: { 'country' : country } });
        this.$router.push({name : page,query:{'country' : this.$route.query.country}});
      }else{
        this.$router.push(page)
      }
    },
  },
  created() {
    if (this.$route.path == "/gdp") {
      this.tab = "GDP";
    } else if (this.$route.path == "/money-and-price") {
      this.tab = "Money&Prices";
    } else if (this.$route.path == "/markets") {
      this.tab = "Markets";
    } else if (this.$route.path == "/markets") {
      this.tab = "Markets";
    } else if (this.$route.path == "/business-consumer") {
      this.tab = "Business&Consumer";
    } else if (this.$route.path == "/revenue-and-expenditure") {
      this.tab = "Revevue&Expenditure";
    } else if (this.$route.path == "/labour-and-health") {
      this.tab = "Labour&Health";
    } else if (this.$route.path == "/forex-and-trade") {
      this.tab = "Forex&Trade";
    } else if (this.$route.path == "/revenue-and-expenditure") {
      this.tab = "Export&Import";
    } else if (this.$route.path == "/latest-news") {
      this.tab = "News";
    } else {
      this.tab == "Overview";
    }
  },
};
</script>
